.legendContainer {
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 40px;
  padding: 10px;
  margin-top: 10px;
  background-color:rgba(220,220,220,0.7);
  border-radius: 10px;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  justify-content: center;
  text-align: center;
  
  font-weight: bold;

}

.legendPinContainer {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    width: 200px;
    height: 60px;
    padding: 10px;
    background-color:rgba(220,220,220,0.7);
    border-radius: 10px;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
}

.legendHeader {
  margin-top: -5px;
  font-weight: bold;
}

.legendElements {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 1px;
}

.legendElement {
 flex: 1;
 color: white;
 height:16px;
 padding: 1px;
 font-weight: bold;
}

.pinLegendElement {
    /*flex: 1;*/
    height:30px;
    padding: 1px;
    font-weight: bold;
}

.legendElementVeryGood {
    background: #57B108;
    border-radius: 5px 0 0 5px;
}

.legendElementGood {
    background: #B0DD10;
}

.legendElementModerate {
    background: #FFD911;
}

.legendElementBad {
    background: #E50000;
}
