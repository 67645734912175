.dropdown {
  position: relative;
  display: inline-block;
  padding-top: 5px;
  text-align: center;
  height: 40px;
  font-weight: bold;
  color: #d81b60;
    top: 10px;
}

.dropdown:hover {
    border-radius: 10px 10px 0px 0px;   
}

.dropdown:hover .dropdown-content {
    color: black
}

.dropdown:hover .btnIcon {
    color: #d81b60;
}

.dropdown-content {
  display: none;
  background-color: #f9f9f9;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: -10px;
  border-radius: 0px 0px 10px 10px;
  margin-top: 0px
}

.dropdown-content > p {
   padding: 4px;
   cursor: pointer;
}

.dropdown-content > p:hover {
   border: solid 2px rgba(236, 64, 122, 0.61);
   border-radius: 3px;
}

.dropdown-content .selected {
    border: solid 2px #ec407a;
    border-radius: 5px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownTextContainer {
    display: flex;
}

.aqiIcon {
    color: #ec407a;
    margin-left: 5px;
    margin-bottom: -2px;
}

.btnIcon {
    position: relative;
    top: -5px;
    color: #d81b60;
}

.btnText {
  flex: 9;
  justify-content: center;
  text-align: center;
}
