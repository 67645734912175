.modal {
    position: fixed;
    z-index: 999500;
    background-color: transparent;
    /*width: 70%;*/
    /*border: 0px;*/
    /*box-shadow: 1px 1px 1px black;*/
    padding: 16px;
    top: 0%;
    height: 100%;
    /*box-sizing: border-box;*/
    transition: all 0.3s ease-out;
}

.modalLiepaja {
    position: fixed;
    z-index: 999500;
    background-color: transparent;
    /*width: 70%;*/
    /*border: 0px;*/
    /*box-shadow: 1px 1px 1px black;*/
    padding: 10px;
    top: 0%;
    height: 100%;
    /*box-sizing: border-box;*/
    transition: all 0.3s ease-out;
}

@media (min-width: 450px) {
    .modal {
        width: 450px;
        right: calc(2%);
    }
}
@media (min-width: 640px) {
    .modalLiepaja {
        width: 640px;
        right: calc(2%);
    }
}