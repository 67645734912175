.popupContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
}

.popupContainer > :nth-child(1){
   font-weight: bold;
   padding: 4px;
}

.popupContainer > :nth-child(2){
    color: lightgray;
    font-size: 10px;
}

.noiseLegend {
    font-size: 10px;
    text-align: left;
    justify-content: left;
}

.gm-style-iw-c > :nth-child(2) {display: none;}
